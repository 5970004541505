import { Component } from "react";
import "./ThreeColumns.scss";

export default class ThreeColumns extends Component {
  render() {
    const { column1, column2, column3 } = this.props;

    let className = "three-column";

    var layoutElement;
    if (column1 && column2 && column3) {
      layoutElement = (
        <div className="grid">
          <div className="col6 s3 three-column__column1">{column1}</div>
          <div className="col6 s10 three-column__column2">{column2}</div>
          <div className="col6 s17 three-column__column3">{column3}</div>
        </div>
      );
    }

    return <div className={className}>{layoutElement}</div>;
  }
}
