import { Component } from "react";
import "./MoodGallery.scss";

export default class MoodGallery extends Component {
  render() {
    const { image1, image2, image3, image4, title, version } = this.props;

    let className = "mood-gallery";

    var layoutElement;

    switch (version) {
      case "threePicturesSquare":
        layoutElement = (
          <div>
            <div className="grid mood-gallery__row1">
              <div className="col16">
                <div className="image image-16-9">
                  <img src={image1} alt="" />
                </div>
              </div>
              <div className="col8 s17">
                <div className="image image-1-1">
                  <img src={image2} alt="" />
                </div>
              </div>
            </div>
            <div className="grid mood-gallery__row2">
              <div className="col6 s4 mood-gallery__title">
                <h2>{title}</h2>
              </div>
              <div className="col9 s13">
                <div className="image image-16-9">
                  <img src={image3} alt="" />
                </div>
              </div>
            </div>
          </div>
        );

        break;
      case "fourPictures":
        layoutElement = (
          <div>
            <div className="grid mood-gallery__row1">
              <div className="col16">
                <div className="image image-16-9">
                  <img src={image1} alt="" />
                </div>
              </div>
              <div className="col8 s17">
                <div className="image image-4-3">
                  <img src={image2} alt="" />
                </div>
              </div>
            </div>
            <div className="grid mood-gallery__row2">
              <div className="col6 s7">
                <div className="image image-1-1">
                  <img src={image3} alt="" />
                </div>
              </div>
              <div className="col9 s13">
                <div className="image image-16-9">
                  <img src={image4} alt="" />
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        layoutElement = (
          <div>
            <div className="grid mood-gallery__row1">
              <div className="col16">
                <div className="image image-16-9">
                  <img src={image1} alt="" />
                </div>
              </div>
              <div className="col8 s17">
                <div className="image image-4-3">
                  <img src={image2} alt="" />
                </div>
              </div>
            </div>
            <div className="grid mood-gallery__row2">
              <div className="col6 s4 mood-gallery__title">
                <h2>{title}</h2>
              </div>
              <div className="col9 s13">
                <div className="image image-16-9">
                  <img src={image3} alt="" />
                </div>
              </div>
            </div>
          </div>
        );
    }

    return <div className={className}>{layoutElement}</div>;
  }
}
