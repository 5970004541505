import { Component } from "react";
import "./Select.scss";

export default class Select extends Component {
  render() {
    const { children, error, errorMessage, ...rest } = this.props;

    let className = "select" + (error ? " select--error" : "");

    var errorElement;
    if (error && errorMessage) {
      errorElement = (
        <div className="select__error-message">{errorMessage}</div>
      );
    }

    return (
      <div className={className}>
        <div className="select__arrow">
          <svg width="24px" height="24px" viewBox="0 0 24 24">
            <path
              d="M2.5,7.5 L11.7928932,16.7928932 C12.1834175,17.1834175 12.8165825,17.1834175 13.2071068,16.7928932 L22.5,7.5 L22.5,7.5"
              fill="none"
              stroke="#16335F"
              strokeWidth="1.3"
            ></path>
          </svg>
        </div>
        <select {...rest}>{children}</select>
        {errorElement}
      </div>
    );
  }
}
