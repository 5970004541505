import { getImage } from "Helpers/image";
import { url } from "Helpers/format";

const specialPricePeriod = new Date().toISOString() <= "2023-10-31";

export default class StockVehicle {
  constructor({
    price_special,
    fullprice,
    price,
    fullprice_special,
    list_price,
    special_discount,
    family_id,
    chassis,
    chasis,
    discount,
    ...props
  }) {
    for (let key in props) {
      if (props.hasOwnProperty(key)) {
        this[key] = props[key];
      }
    }

    this.chassis = chassis || chasis;

    if (fullprice) {
      this.fullprice =
        fullprice_special > 0
          ? parseInt(fullprice_special, 10)
          : parseInt(fullprice, 10);
      this.list_price = parseInt(list_price, 10);
      this.discount = parseInt(discount, 10);
    } else if (price) {
      this.fullprice = parseInt(price, 10);
      this.list_price = 0;
      this.discount = 0;
    }

    this.special_discount = parseInt(special_discount, 10);
    this.family_id = parseInt(family_id, 10);

    if (specialPricePeriod && this.name && this.name.toLowerCase().includes('polo') && this.name.toLowerCase().includes('track')) {
      this.govPrice = true;
    }
  }

  get thumbnail() {
    return getImage(this);
  }

  get family_url_name() {
    return url(this.family);
  }

  getDiscountFromCoupon(promotion) {
    const price = this.getPrice();

    if (promotion.money_discount) {
      return promotion.money_discount;
    }

    return (promotion.percentage_discount * price) / 100;
  }

  getPrice(promotion = null) {
    return promotion
      ? this.fullprice - this.getDiscountFromCoupon(promotion)
      : this.fullprice;
  }
}
