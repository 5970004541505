import Button from "Components/Atoms/Button/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import SidePanel from "Components/Layout/SidePanel/index.jsx";
import TextArea from "Components/Atoms/Forms/TextArea/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";
import { currency } from 'Helpers/format';
import { Link } from 'react-router-dom';
import { request as sendRequest } from 'Services/accessory';
import { useState } from 'react';

function Cart({ cart, goBack, reset, remove }) {
  const [step, setStep] = useState(1);
  const [request, setRequest] = useState({
    name: '',
    lastname: '',
    area_code: '261',
    telephone: '',
    brand_id: 1,
    comments: ''
  });
  const [error, setError] = useState(false);

  function handleChange(e) {
    setRequest({ ...request, [e.currentTarget.name]: e.currentTarget.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setError(undefined);

    const success = await sendRequest(request, cart);

    if (!success) {
      setError(true);
      return;
    }

    setStep(3);
    reset();
  }

  if (step === 1) {
    return (
      <SidePanel small open onCloseClick={goBack}>
        <h3>
          <strong>Tu pedido</strong>
        </h3>
        <br />

        {cart.length === 0 && (
          <div>
            <p>Tu carrito está vacío.</p>
            <p>Agrega accesorios al carrito y nos contactaremos para coordinar el pago y la entrega o instalación del pedido.</p>
          </div>
        )}

        {cart.length > 0 && (
          <>
            <table
              className="table table--small"
              cellSpacing="0"
              cellPadding="0"
              width="100%"
            >
              <tbody>
                {cart.map((accessory, i) => (
                  <tr key={i}>
                    <td className="td-one-line">
                      {accessory.name}
                    </td>
                    <td className="td-right">{currency(accessory.final_price)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th width="70%">Total </th>
                  <th className="td-right">{currency(cart.reduce((total, accessory) => total + accessory.final_price, 0))}</th>
                </tr>
              </tfoot>
            </table>
            <Section>
              <Button color="ghost" onClick={() => setStep(2)}>Solicitar accesorios</Button>
              <br />
              <br />
              <Button onClick={() => { reset(); goBack(); }}>Limpiar pedido</Button>
            </Section>
          </>
        )}
      </SidePanel>
    )
  }

  if (step === 2) {
    const incomplete = !request.name || !request.lastname || !request.area_code || !request.telephone || !request.email;

    return (
      <SidePanel backButton="Volver al pedido" onBackButtonClick={() => setStep(1)} open onCloseClick={goBack}>
        <h3>
          <strong>Pedido de accesorios</strong>
        </h3>
        <p>
          Completa el siguiente formulario con tus datos y te contactaremos para
          coordinar el pago y la entrega o instalación de los accesorios.
        </p>
        <Section>
          <form onSubmit={handleSubmit}>
            <FormRow>
              <FormField label="Nombre" inline>
                <TextField name="name" onChange={handleChange} value={request.name} required/>
              </FormField>
              <FormField label="Apellido" inline>
                <TextField name="lastname" onChange={handleChange} value={request.lastname} required/>
              </FormField>
            </FormRow>
            <FormRow>
              <FormField small label="Cod. de área" inline>
                <TextField type="tel" name="area_code" onChange={handleChange} value={request.area_code} required/>
              </FormField>
              <FormField label="Teléfono" inline>
                <TextField type="tel" name="telephone" onChange={handleChange} value={request.telephone} required/>
              </FormField>
            </FormRow>
            <FormRow>
              <FormField label="Email">
                <TextField type="email" name="email" onChange={handleChange} value={request.email} required/>
              </FormField>
            </FormRow>
            <FormRow>
              <FormField label="Comentarios">
                <TextArea name="comments" onChange={handleChange} value={request.comments} />
              </FormField>
            </FormRow>
            <FormRow>
              <Button type="submit" disabled={error === undefined || incomplete}>Hacer Pedido</Button>
            </FormRow>
            {error && (
              <FormRow>
                <p>Hubo un error al realizar tu pedido. Por favor intenta nuevamente o <Link to="/contacto">contactanos</Link></p>
              </FormRow>
            )}
          </form>
        </Section>
      </SidePanel>
    );
  }

  return (
    <SidePanel small open onCloseClick={goBack}>
      <h3>
        <strong>Pedido enviado exitosamente</strong>
      </h3>
      <br />

      <p>¡Muchas gracias!</p>
      <p>Nos estaremos comunicando con vos a la brevedad para coordinar el pago, entrega y/o instalación de tus accesorios.</p>

      <Button onClick={() => { goBack(); }}>Cerrar</Button>
    </SidePanel>
  )
}

export default Cart;
