import Stage from "Components/Layout/Stage/index.jsx";
import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import Plan from "Components/Molecules/Plan/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import Basic from "Components/Layout/Editorials/Basic/index.jsx";
import MainLink from "Components/Atoms/MainLink/index.jsx";
import ThreeColumns from "Components/Layout/Editorials/ThreeColumns/index.jsx";
import StageImage from "images/photos/Stages/Techniklexikon_mood-gallery6_19-6_f-cc.jpg";
import Image2 from "images/photos/Stages/ST_1-1_Conformity-Ingredients.jpg";
import Image3 from "images/photos/Stages/16-9_quick-and-free-of-charge.jpg";
import { useSavingPlans } from "Components/Context/Parameters";
import {Helmet} from "react-helmet";

function Plans() {
  const plans = useSavingPlans();

  let plansTitle = (
    <>
      Los mejores planes para
      <strong> acceder a tu próximo 0km</strong>
    </>
  );

  let stageTitle = (
    <>
      Autoahorro
      <br />
      <strong> Volkswagen</strong>
    </>
  );

  let breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Autoahorro", link: "/autoahorro" },
  ];

  let contractBasicTitle = (
    <>
      Descargá el <strong> contrato</strong>
    </>
  );
  let suscriptionBasicTitle = (
    <>
      ¿Ya estás
      <strong> suscripto?</strong>
    </>
  );

  let contractBasic = (
    <Basic
      layout="basic-6-square"
      image={Image2}
      title={contractBasicTitle}
      description="Descargá el contrato, llenalo y acercate a nuestras oficinas en San Martin Sur 600 (Godoy Cruz) o Avenida Mitre 721 (San Rafael)."
    >
      <MainLink
        icon
        url="https://vwyacopini.com.ar/vw-website/docs/contrato.pdf"
      >
        Descargá el contrato
      </MainLink>
    </Basic>
  );
  let suscriptionBasic = (
    <Basic
      layout="basic-6-square"
      image={Image3}
      title={suscriptionBasicTitle}
      description="Si ya estas suscripto a un plan utilizá nuestros servicios online para administrarlo."
    >
      <MainLink icon url="https://www.autoahorro.com.ar/">
        Cupón de Pago
      </MainLink>
    </Basic>
  );
  let allocationBasic = <></>;

  /*(
    <Basic
      layout="basic-6-square"
      image={Image4}
      title={(
        <>
          Actos de
          <strong> adjudicación</strong>,
        </>
      )}
      description="Conocé los resultados de los actos de adjudicación de nuestros planes de ahorro."
    >
      <MainLink url="/autoahorro/adjudicacion" icon>
        Resultados del último acto
      </MainLink>
    </Basic>
  );*/

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Autoahorro Volkswagen</title>
        <meta name="description" content="Autoahorro Volkswagen - Planes de ahorro - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <Stage title={stageTitle} image={StageImage} cta="Ver Planes" url={`#planes`} />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />
      <Intro
        title={plansTitle}
        subtitle="Autoahorro Volkswagen "
        description="Autoahorro Volkswagen te da la posibilidad de acceder, mediante el pago de cuotas mensuales en pesos, sujetas a variaciones de lista de precios, y de bajo monto, sin anticipos ni requisitos de ingreso, a los vehículos de la amplia gama de modelos que Volkswagen pensó para vos."
      />
      <ThreeColumns
        column1={contractBasic}
        column2={suscriptionBasic}
        column3={allocationBasic}
      />
      <Section first>
        <div className="grid" id="planes">
          <div className="col20 s3">
            <h2>
              Planes <strong>Vigentes</strong>
            </h2>
          </div>
        </div>
      </Section>
      <Section>
        <div className="grid">
          <div className="col20 s3 ">
            <h5>Destacados</h5>
          </div>
        </div>
        <div className="grid">
          <div className="col20 s3 col24m">
            <div className="grid grid--highlightedPlans">
              {plans.map((plan) => {
                if (plan.priority === 0) {
                  return null;
                }

                return (
                  <Plan
                    key={plan.id}
                    plan={plan}
                    highlighted
                    url={plan.url}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="grid">
          <div className="col20 s3">
            <h5>Todos los planes</h5>
          </div>
        </div>
        <div className="grid">
          <div className="col20 s3 col24m">
            <div className="grid grid--offers">
              {plans.map((plan) => {
                if (plan.priority !== 0) {
                  return null;
                }

                return <Plan key={plan.id} plan={plan} url={plan.url} />;
              })}
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </>
  );
}

export default Plans;
