import { url } from "Helpers/format";

export default class Subfamily {
  constructor({ discount, ...props }) {
    for (let key in props) {
      if (props.hasOwnProperty(key)) {
        this[key] = props[key];
      }
    }

    this.discount = parseInt(discount, 10);
  }

  get url_name() {
    return url(this.name);
  }
}
