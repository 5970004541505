import { Component } from "react";
import "./FormRow.scss";

export default class FormRow extends Component {
  render() {
    const { children, columns } = this.props;

    let className = "form-row" + (columns ? " form-row--" + columns : "");

    return <div className={className}>{children}</div>;
  }
}
