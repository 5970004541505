import { Component } from "react";
import "./FixedButtons.scss";

function FixedButtons({ buttons }) {
  return (
    <div className="fixed-buttons">
      {buttons.map(({ text, url, onClick }, index) => {
        var classNameButton = "fixed-button";
        return (
          <div className={classNameButton} key={index}>
            {url && <a href={url}>{text}</a>}
            {onClick && <span onClick={onClick}>{text}</span>}
          </div>
        );
      })}
    </div>
  );
}

export default FixedButtons;
