import { Component } from "react";
import { Link } from "react-router-dom";
import "./MainLink.scss";

export default class MainLink extends Component {
  render() {
    const { children, color, icon, type, url, small, ...etc } = this.props;

    let className =
      "main-link" +
      (color ? " main-link--" + color : "") +
      (type ? " main-link--" + type : "") +
      (small ? " main-link--small" : "") +
      (icon ? " main-link--icon" : "");

    var iconElement;
    if (icon) {
      iconElement = (
        <span className="main-link__icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            role="img"
          >
            <path d="M23.489.483c-.621-.611-.728-.645-10.934-.085l.184 3.39c2.268-.123 4.02-.338 5.427-.39L7.635 13.866l2.398 2.408L20.562 5.777c-.061 1.34-.265 3.162-.404 5.404l3.394.21c.623-10.06.591-10.237-.063-10.908z"></path>
            <path d="M19.6 15.014h3.398V24H0V1h9v3.395H3.399v16.21H19.6z"></path>
          </svg>
        </span>
      );
    }

    if (url.charAt(0) === "/") {
      return (
        <Link to={url} className={className}>
          <span>{children}</span>
          {iconElement}
        </Link>
      );
    }

    return (
      <a href={url} className={className} {...etc}>
        <span>{children}</span>
        {iconElement}
      </a>
    );
  }
}
