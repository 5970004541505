import axios from "axios";
import Button from "Components/Atoms/Button/index.jsx";
import Modal from "Components/Layout/Modal/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";
import { useState } from "react";
import { Link } from "react-router-dom";

const PriceRequest = ({ family, priceRequested }) => {
  const [data, setData] = useState({
    name: "",
    lastname: "",
    email: "",
    telephone: "",
    vehicle: family.name,
  });
  const [requesting, setRequesting] = useState(false);

  async function submit(e) {
    e.preventDefault();

    setRequesting(true);

    try {
      await axios.post("/api/vw/request-price", {
        data: {
          ...data,
          brand_id: 1,
        },
      });
    } catch (e) {
      console.error(e);
    }

    setRequesting(false);
    priceRequested();
  }

  return (
    <Modal title="Consulta nuestros precios" open>
      <p className="p p-s">
        Completando el formulario podrás consultar nuestros precios,
        disponibilidad y reservar tu vehículo online y concretar la operación en
        nuestras oficinas.
      </p>
      <form onSubmit={submit}>
        <FormRow>
          <FormField label="Nombre" inline>
            <TextField
              name="name"
              value={data.name}
              onChange={(e) =>
                setData({
                  ...data,
                  [e.currentTarget.name]: e.currentTarget.value,
                })
              }
              required
            />
          </FormField>
          <FormField label="Apellido" inline>
            <TextField
              name="lastname"
              value={data.lastname}
              onChange={(e) =>
                setData({
                  ...data,
                  [e.currentTarget.name]: e.currentTarget.value,
                })
              }
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField label="Email" large>
            <TextField
              type="email"
              name="email"
              value={data.email}
              onChange={(e) =>
                setData({
                  ...data,
                  [e.currentTarget.name]: e.currentTarget.value,
                })
              }
              required
            />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField label="Teléfono" mid>
            <TextField
              type="tel"
              name="telephone"
              value={data.telephone}
              onChange={(e) =>
                setData({
                  ...data,
                  [e.currentTarget.name]: e.currentTarget.value,
                })
              }
              required
            />
          </FormField>
        </FormRow>
        {requesting ? (
          <div>Enviando...</div>
        ) : (
          <>
            <Link to={`/modelos/${family.url_name}`}>
              <Button color="ghost">Cancelar</Button>
            </Link>
            <Button type="submit">Ver Precios</Button>
          </>
        )}
      </form>
    </Modal>
  );
};

export default PriceRequest;
