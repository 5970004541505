import { Component } from "react";
import "./Checkbox.scss";

export default class Checkbox extends Component {
  render() {
    const { error, name, text, image, checked, inline, onChange, ...etc } = this.props;

    let className = "checkbox" + (error ? " checkbox--error" : "") + (inline ? " checkbox--inline" : "");

    var imageElement;
    if (image) {
      imageElement = (
        <span className="checkbox__image">
          <img src={image} alt={text} />
        </span>
      );
    }

    return (
      <div className={className} onClick={() => onChange({ currentTarget: { name, value: !checked } })}>
        {imageElement}
        {text}
        <input type="checkbox" name={name} checked={checked ? "checked" : ""} onChange={onChange} {...etc} />

        <span className="checkbox__checkmark"></span>
      </div>
    );
  }
}
