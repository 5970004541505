import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import Unit from "Components/Molecules/Unit/index.jsx";
import SectionHero from "Components/Layout/SectionHero/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import PriceRequest from "Components/Molecules/PriceRequest";
import {
  useFamily,
  useSubfamily,
  useOffers,
  useStock,
} from "Components/Context/Parameters";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";

function Units(props) {
  const params = useParams();
  const family = useFamily(params.family);
  const subfamily = useSubfamily(family, params.subfamily);
  const offers = useOffers(family);
  const [showPrices, setShowPrices] = useState(
    !!sessionStorage.getItem("priceRequested")
  );
  let vehicles = useStock(family, subfamily);

  if (props.offers) {
    vehicles = offers;
  }

  const breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Modelos", link: "/modelos" },
    { id: 3, name: family.name, link: `/modelos/${family.url_name}` },
  ];

  if (subfamily) {
    breadcrumbsList.push({
      id: 4,
      name: subfamily.name,
      link: `/modelos/${family.url_name}`,
    });
  }

  if (props.offers) {
    breadcrumbsList.push({
      id: 5,
      name: "Oportunidades en stock",
      link: `/modelos/${family.url_name}/oportunidades`,
    });
  } else {
    breadcrumbsList.push({
      id: 5,
      name: "Unidades en stock",
      link: `/modelos/${family.url_name}/unidades`,
    });
  }

  let introTitle = (
    <strong>
      Hay {vehicles.length}{" "}
      {!vehicles.length || vehicles.length > 1
        ? "unidades disponibles"
        : "unidad disponible"}
    </strong>
  );
  let introSubTitle = [family.name];

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Unidades en stock {family.name}</title>
        <meta name="description" content="Vehículos 0km en stock - Volkswagen - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      {!showPrices && (
        <PriceRequest
          family={family}
          priceRequested={() => {
            setShowPrices(true);
            sessionStorage.setItem("priceRequested", "true");
          }}
        />
      )}
      <SectionHero type="imageOnly" title="Unidades" image={family.hero} />
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />

      <Intro title={introTitle} subtitle={introSubTitle} titleOnly />
      <Section>
        {vehicles.map((vehicle) => (
          <Unit
            vehicle={vehicle}
            family={family}
            key={vehicle.id}
            image={family.thumbnail}
            hidePrices={!showPrices}
          />
        ))}
      </Section>

      <Footer />
    </>
  );
}

export default Units;
