import axios from "axios";
import Button from "Components/Atoms/Button/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import Select from "Components/Atoms/Forms/Select/index.jsx";
import { useState } from "react";
import { currency } from "Helpers/format";

const ServiceFinder = ({ vehicles, engines, transmissions }) => {
  const [engine, setEngine] = useState("");
  const [transmission, setTransmission] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [categories, setCategories] = useState(null);

  function search(e) {
    e.preventDefault();

    axios
      .get(
        `/api/vw/services?vehicle_id=${vehicle}&engine_id=${engine}&transmission_id=${transmission}&legacy_service=false`
      )
      .then((response) => setCategories(response.data));
  }

  return (
    <>
      <Section>
        <div className="grid">
          <div className="col col20 s3">
            <h3>
              <strong>Buscá el servicio que necesites</strong>
            </h3>
            <br />
            <form onSubmit={search}>
              <FormRow>
                <FormField label="Modelo" select inline>
                  <Select
                    value={vehicle}
                    onChange={(e) => setVehicle(e.currentTarget.value)}
                  >
                    <option>Seleccionar</option>
                    {vehicles.map((vehicle) => (
                      <option value={vehicle.id} key={vehicle.id}>
                        {vehicle.name}
                      </option>
                    ))}
                  </Select>
                </FormField>

                <FormField label="Motor" inline>
                  <Select
                    value={engine}
                    onChange={(e) => setEngine(e.currentTarget.value)}
                  >
                    <option>Seleccionar</option>
                    {engines.map((engine) => (
                      <option value={engine.id} key={engine.id}>
                        {engine.name}
                      </option>
                    ))}
                  </Select>
                </FormField>
                <FormField label="Transmisión" inline>
                  <Select
                    value={transmission}
                    onChange={(e) => setTransmission(e.currentTarget.value)}
                  >
                    <option>Seleccionar</option>
                    {transmissions.map((transmission) => (
                      <option value={transmission.id} key={transmission.id}>
                        {transmission.name}
                      </option>
                    ))}
                  </Select>
                </FormField>
                <FormField label=" " inline>
                  <Button
                    type="submit"
                    disabled={!vehicle || !engine || !transmission}
                  >
                    Buscar
                  </Button>
                </FormField>
              </FormRow>
            </form>
          </div>
        </div>
      </Section>

      <br />

      <Section>
        <div className="grid">
          <div className="col col20 s3">
            {categories && (
              <>
                <h3>
                  <strong>Servicios disponibles</strong>
                </h3>
                <br />
              </>
            )}

            {categories && !categories.length && (
              <p>No encontramos servicios para las opciones seleccionadas.</p>
            )}

            {categories &&
              categories.map((category) => {
                if (!category.services.length) {
                  return null;
                }

                return (
                  <div key={category.id}>
                    <h5>{category.name}</h5>
                    <table
                      className="table"
                      cellSpacing="0"
                      cellPadding="0"
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th width="20%">Servicio </th>
                          <th>Descripción </th>

                          <th width="20%" className="td-right">
                            Precio
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {category.services.map((service) => (
                          <tr key={service.id}>
                            <td>{service.name}</td>
                            <td>{service.description} </td>
                            <td className="td-right">
                              {currency(
                                service.price_promotion || service.price_default
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <br />
                  </div>
                );
              })}
          </div>
        </div>
      </Section>
    </>
  );
};

export default ServiceFinder;
