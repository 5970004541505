import { Component } from "react";
import "./Model.scss";
import Button from "../../Atoms/Button/index.jsx";
import MainLink from "../../Atoms/MainLink/index.jsx";
import { Link } from "react-router-dom";

export default class Model extends Component {
  render() {
    const { model, url, onlyImage } = this.props;

    const color = model.thumbnailColor || null;

    let className =
      "model" +
      (color ? " model--" + color : "") +
      (onlyImage ? " model--onlyImage" : "");

    var modelElement;
    if (onlyImage) {
      modelElement = (
        <div className={className}>
          <div className="model__hero">
            <div className="model__image">
              <img src={model.thumbnail} alt={model.name} />
            </div>
          </div>
        </div>
      );
    } else {
      modelElement = (
        <div className={className}>
          <div className="model__hero">
            <div className="model__image">
              <a href={url}>
                <img src={model.thumbnail} alt={model.name} />
              </a>
            </div>
            <div className="model__name">{model.name}</div>
          </div>
          <div className="model__content">
            <div className="model__content__button">
              <Link to={url}>
                <Button small color="ghost">
                  Conocelo
                </Button>
              </Link>
            </div>
            <div className="model__content__link">
              <MainLink small url={url}>
                Elegí tu versión
              </MainLink>
            </div>
          </div>
        </div>
      );
    }

    return <div>{modelElement}</div>;
  }
}
