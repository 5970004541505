import Footer from "Components/Layout/Footer/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import Offer from "Components/Molecules/Offer/index.jsx";
import Intro from "Components/Layout/Editorials/Intro/index.jsx";
import {
  useFamilies,
  useOffers,
  useServiceOffers,
} from "Components/Context/Parameters";
import { currency } from "Helpers/format";
import {Helmet} from "react-helmet";

function groupByFamily(families, offers) {
  if (!families || !families.length) {
    return [];
  }

  offers.forEach((offer) => {
    const family = families.find((family) => family.id === offer.family_id);

    if (!family) {
      console.error(`[Offers] family ${offer.family} not found.`);
      return;
    }

    if (
      !family.biggest_discount ||
      offer.special_discount > family.biggest_discount
    ) {
      family.biggest_discount = offer.special_discount;
    }

    family.offers.push(offer);
  });

  return families;
}

function Offers() {
  const families = useFamilies();
  const offers = groupByFamily(families, useOffers());
  const serviceOffers = useServiceOffers();

  const serviciosTitle = (
    <>
      Ofertas de <strong> servicios</strong>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Oportunidades 0km</title>
        <meta name="description" content="Vehículos 0km con precio especial - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <Section first>
        <div className="grid ">
          <div className="col20 s3">
            <h2>
              Descubre todas <strong>nuestras ofertas</strong>
            </h2>
          </div>
        </div>
      </Section>
      <Section>
        <div className="grid">
          <div className="col20 s3 ">
            <h5>Oportunidades</h5>
            <p>Consultá nuestras unidades* en stock con precios especiales.</p>
          </div>
        </div>
        <div className="grid">
          <div className="col20 s3 col24m">
            <div className="grid grid--offers">
              {offers.map((offer) => {
                if (!offer.offers.length) {
                  return null;
                }

                return (
                  <Offer
                    key={offer.id}
                    color={offer.color}
                    image={offer.thumbnail}
                    name={offer.name}
                    url={`/modelos/${offer.url_name}/oportunidades`}
                    offerType="opportunity"
                    discount={offer.biggest_discount}
                    units={offer.offers.length}
                  />
                );
              })}
            </div>

            <small>
              * Vehículos únicos con condiciones especiales sujetos a la
              revisión del cliente.
            </small>
          </div>
        </div>
      </Section>
      <Section>
        <div className="grid">
          <div className="col20 s3 ">
            <h5>Descuentos por reserva web</h5>
            <p>
              Reservá a través de nustro sitio web y accedé a importantes
              descuentos.
            </p>
          </div>
        </div>
        <div className="grid">
          <div className="col20 s3 col24m">
            <div className="grid grid--offers">
              {families.map((family) => {
                if (!family.discount) {
                  return null;
                }

                return (
                  <Offer
                    key={family.id}
                    color={family.color}
                    image={family.thumbnail}
                    name={family.name}
                    url={`/modelos/${family.url_name}`}
                    offerType="web"
                    discount={family.discount}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <Intro
          title={serviciosTitle}
          description="Ahora podés disfrutar de los mejores precios tambien en nuestros talleres. Aprovechá nuestros descuentos para mantaner tu auto en las mejores condiciones."
        />
        <div className="grid">
          <div className="col16 s8 col24m">
            <table className="table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th width="30%">Vehículo</th>
                  <th width="30%">Servicio</th>
                  <th>Precio</th>
                  <th>Precio promocional</th>
                </tr>
              </thead>
              <tbody>
                {serviceOffers.map((service, i) => (
                  <tr key={i}>
                    <td>{service.model_name}</td>
                    <td>{service.name}</td>
                    <td>{currency(service.price_default)}</td>
                    <td>{currency(service.price_promotion)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Section>
      <Footer />
    </>
  );
}

export default Offers;
