import { useState, useEffect } from "react";
import "./Modal.scss";

const Modal = ({ children, title, open, imageOnly, link, close, onClose }) => {
  const [isOpen, setIsOpen] = useState(open);

  if (!close) {
    close = () => {
      setIsOpen(!isOpen);
      if (onClose) {
        onClose();
      }
    };
  }

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  let className =
    "modal" +
    (isOpen ? " modal--open" : "") +
    (imageOnly ? " modal--image-only" : "");

  var linkElement;
  if (link && imageOnly) {
    linkElement = (
      <a
        href={link}
        className="modal__link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
      </a>
    );
  }

  var modalElement;
  if (!imageOnly) {
    modalElement = (
      <div className="modal__body">
        <div className="modal__header">
          <h4>
            {" "}
            <strong>{title}</strong>
          </h4>
          <div className="modal__header__close">
            <div className="modal__header__close__button" onClick={close} />
          </div>
        </div>
        <div className="modal__content">{children}</div>
      </div>
    );
  } else {
    modalElement = (
      <div className="modal__body">
        <div className="modal__header">
          <div className="modal__header__close">
            <div className="modal__header__close__button" onClick={close} />
          </div>
        </div>
        {linkElement}
        {children}
      </div>
    );
  }

  return (
    <div className={className}>
      {modalElement}
      <div className="modal__overlay"></div>
    </div>
  );
};

export default Modal;
