import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";

function PlansAllocation() {
  let breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Autoahorro", link: "/autoahorro" },
    { id: 3, name: "Actos de Adjudicación", link: "/autoahorro/adjudicacion" },
  ];

  return (
    <>
      <Section first>
        <Breadcrumbs linkList={breadcrumbsList} withCurrent />
      </Section>
      <Section>
        <div className="grid">
          <div className="col col20 s3">
            <h2>
              <strong>Actos de Adjudicación</strong>
            </h2>
            <p>ACTO N° 496 realizado el día 12/03/2019 en: Yacopini Süd S.A.</p>
            <br />
            <FormRow>
              <FormField label="Grupo" select inline>
                <TextField placeholder="XXXX"></TextField>
              </FormField>

              <FormField label=" " inline>
                <Button>Buscar</Button>
              </FormField>
            </FormRow>
          </div>
        </div>
      </Section>
      <br />
      <Section>
        <div className="grid">
          <div className="col col20 s3">
            <h5>
              <strong>Resultados del Grupo XXXX</strong>
            </h5>
            <br />

            <table
              className="table"
              cellSpacing="0"
              cellPadding="0"
              width="100%"
            >
              <thead>
                <tr>
                  <th width="20%">Servicio </th>
                  <th>Descripción </th>

                  <th width="20%" className="td-right">
                    Precio
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Express </td>
                  <td>
                    Cambio de aceite y filtro de aceite - chequeo de 25 puntos
                    de seguridad - Diagnostico computarizado - para autos fuera
                    del período de garantia{" "}
                  </td>
                  <td className="td-right">$ 1.497.350</td>
                </tr>
                <tr>
                  <td>Express </td>
                  <td>
                    Cambio de aceite y filtro de aceite - chequeo de 25 puntos
                    de seguridad - Diagnostico computarizado - para autos fuera
                    del período de garantia{" "}
                  </td>
                  <td className="td-right">$ 1.497.350</td>
                </tr>
                <tr>
                  <td>Express </td>
                  <td>
                    Cambio de aceite y filtro de aceite - chequeo de 25 puntos
                    de seguridad - Diagnostico computarizado - para autos fuera
                    del período de garantia{" "}
                  </td>
                  <td className="td-right">$ 1.497.350</td>
                </tr>
                <tr>
                  <td>Express </td>
                  <td>
                    Cambio de aceite y filtro de aceite - chequeo de 25 puntos
                    de seguridad - Diagnostico computarizado - para autos fuera
                    del período de garantia{" "}
                  </td>
                  <td className="td-right">$ 1.497.350</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Section>

      <Footer />
    </>
  );
}

export default PlansAllocation;
