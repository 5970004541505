import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

let timeout;

const TimeLeft = ({ coupon }) => {
  const [remaining, setRemaining] = useState('');

  function calc() {
    const now = moment.tz(coupon.expiration.timezone);
    const expiration = moment.tz(
      coupon.expiration.date,
      coupon.expiration.timezone
    );

    setRemaining(expiration.diff(now, 'seconds'));
  }

  function program() {
    calc();

    if (remaining < 0 || remaining === 0) {
      setRemaining(0);
      return;
    }

    timeout = setTimeout(() => {
      program();
    }, 1000);
  }

  useEffect(() => {
    program();

    return () => clearTimeout(timeout);
  }, []); // eslint-disable-line

  if (remaining < 0 || remaining === 0) {
    return <div>Tu código de beneficios ha vencido.</div>;
  }

  const hours = Math.floor(remaining / 3600);
  const minutes = Math.floor((remaining - hours * 3600) / 60);
  const seconds = remaining - hours * 3600 - minutes * 60;

  return (
    <p>
      Tenés{' '}
      <strong>
        {hours > 0 ? `${hours} horas` : ''}{' '}
        {minutes > 0 ? `${minutes} minutos` : ''}{' '}
        {seconds > 0 ? `${seconds} segundos` : ''}
      </strong>{' '}
      para utilizarlo.
    </p>
  );
};

export default TimeLeft;
