import Section from "Components/Layout/Section/index.jsx";
import Seller from "Components/Molecules/Seller/index.jsx";
import SellerImage from "images/seller-testimage.jpeg";
import TimeLeft from './TimeLeft';

const ExpirationBox = ({ coupon, code }) => {
  return (
    <Section>
      <h3>
        <strong>Cupón:</strong> {coupon.name}
      </h3>
      <p>
        Perfecto, tu código {code} es válido. Continua el
        proceso de suscripción para acceder al plan con estos
        beneficios. Recordá que tenés un tiempo limitado para usarlo.
      </p>
      <TimeLeft coupon={coupon} />

      <Section>
        <h4>
          <strong>Tu asesor es:</strong>
        </h4>
        <div className="sellers__block">
          <Seller
            name={coupon.salesman.name}
            whatsapp={coupon.salesman.whatsapp_number}
            zoom={coupon.salesman.zoom_link}
            initials={`${coupon.salesman.name.charAt(0)}${coupon.salesman.lastname.charAt(0)}`}
            image={coupon.salesman.image ? `https://vwyacopini.com.ar${coupon.salesman.image}` : null}
          />
        </div>
      </Section>
      <Section>
        <h4>
          <strong>Estos son tus beneficios:</strong>
        </h4>
        <table
          className="table"
          cellSpacing="0"
          cellPadding="0"
          width="100%"
        >
          <tbody>
            {coupon.benefits.map((benefit, i) => (
              <tr key={i}>
                <td>
                  {benefit}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
    </Section>
  )
}

export default ExpirationBox;
