import { Component } from "react";
import "./FormField.scss";

export default class FormField extends Component {
  render() {
    const {
      children,
      label,
      inline,
      helpText,
      small,
      mid,
      large,
      full,
      select,
      mandatory,
    } = this.props;

    let className =
      "form-field" +
      (inline ? " form-field--inline" : "") +
      (small ? " form-field--small" : "") +
      (mid ? " form-field--mid" : "") +
      (large ? " form-field--large" : "") +
      (select ? " form-field--select" : "") +
      (full ? " form-field--full" : "");

    var mandatoryElement;
    if (mandatory) {
      mandatoryElement = <span>*</span>;
    }
    var labelElement;
    if (label) {
      labelElement = (
        <label>
          {label}
          {mandatoryElement}
        </label>
      );
    }

    var helpElement;
    if (helpText) {
      helpElement = <div className="form-field__help-text">{helpText}</div>;
    }

    return (
      <div className={className}>
        {labelElement}
        {children}
        {helpElement}
      </div>
    );
  }
}
