import Footer from "Components/Layout/Footer/index.jsx";
import Breadcrumbs from "Components/Layout/Breadcrumbs/index.jsx";
import Section from "Components/Layout/Section/index.jsx";
import Button from "Components/Atoms/Button/index.jsx";
import FixedButtons from "Components/Atoms/FixedButtons/index.jsx";
import SectionHero from "Components/Layout/SectionHero/index.jsx";
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";
import Select from "Components/Atoms/Forms/Select/index.jsx";
import Image1 from "images/photos/Stages/2-1_Spark-plugs_FAQ.jpg";
import Accesory from "Components/Molecules/Accesory/index.jsx";
import Cart from "Components/Molecules/Accesory/Cart.jsx";
import { getCategories, getModels, getAccessories } from 'Services/accessory';
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

function Accessories() {
  const [categories, setCategories] = useState([]);
  const [accessories, setAccessories] = useState(undefined);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filter, setFilter] = useState('');
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    getCategories().then(categories => {
      setCategories(categories);
      if (categories.length > 0) {
        setSelectedCategory(categories[0].id);
      }
    });
    getModels().then(models => setModels(models));
  }, []);

  useEffect(() => {
    try {
      const iframe = document.querySelector('[name="Botmaker"]');
      if (iframe) {
        iframe.style.display = 'none';
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (page > 1) {
      setPage(1);
      return;
    }

    search(1);
  }, [selectedModel, selectedCategory]); // eslint-disable-line

  useEffect(() => {
    search(page);
  }, [page]); // eslint-disable-line

  function search(searchPage) {
    if (!selectedCategory) {
      return;
    }

    setAccessories(undefined);

    getAccessories(searchPage, selectedCategory, selectedModel, filter).then(({ data, ...meta }) => {
      setMeta(meta);
      setAccessories(data);
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    search(1);
  }

  const stageDesc = [
    "Aquí podés encontrar todos los servicios que te brindamos para mantener tu auto siempre en las mejores condiciones. Conoce los precios, tiempos y detalles de cada uno para saber todo lo que hacen nuestros mecánicos.",
  ];
  const breadcrumbsList = [
    { id: 1, name: "Página de inicio", link: "/" },
    { id: 2, name: "Accesorios", link: "/accesorios" },
  ];

  const FixedButtonsItems = showCart ? [] : [{ text: `Tu Pedido (${cart.length})`, onClick: () => setShowCart(true) }];

  const accesoriesTitle = (
    <>
      <strong> Accesorios y repuestos </strong>
      <br />
      para tu vehículo.
    </>
  );

  function addToCart(accessory) {
    setCart([ ...cart, accessory ]);
  }

  return (
    <>
      <Helmet>
        <title>Yacopini Süd - Accesorios</title>
        <meta name="description" content="Accesorios para tu Volkswagen - Yacopini Süd - Mendoza - San Rafael" />
      </Helmet>
      <FixedButtons buttons={FixedButtonsItems} />
      <SectionHero
        title={accesoriesTitle}
        image={Image1}
        description={stageDesc}
      />
      {showCart && <Cart goBack={() => setShowCart(false)} reset={() => setCart([])} cart={cart} />}
      <Breadcrumbs linkList={breadcrumbsList} withCurrent />
      <Section>
        <div className="grid">
          <div className="col col20 s3">
            <h3>
              <strong>Buscar Productos</strong>
            </h3>
            <br />
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormField label="Categoría" select inline>
                  <Select value={selectedCategory} onChange={(e) => setSelectedCategory(e.currentTarget.value)}>
                    {categories.map(category => <option value={category.id} key={category.id}>{category.name}</option>)}
                  </Select>
                </FormField>

                <FormField label="Vehículos" select inline>
                  <Select value={selectedModel} onChange={(e) => setSelectedModel(e.currentTarget.value)}>
                    <option value="">Todos</option>
                    {models.map(model => <option value={model.id} key={model.id}>{model.name}</option>)}
                  </Select>
                </FormField>

                <FormField label="Código o nombre" inline>
                  <TextField value={filter} onChange={(e) => setFilter(e.currentTarget.value)} />
                </FormField>

                <FormField label=" " inline>
                  <Button type="submit" disabled={accessories === undefined}>Buscar</Button>
                </FormField>
              </FormRow>
            </form>
          </div>
        </div>
      </Section>
      <br />
      <Section>
        <div className="grid">
          <div className="col col20 s3">
            <h3>
              <strong>Todos los accesorios</strong>
            </h3>
            <br />
          </div>
        </div>
      </Section>
      {meta.last_page > 1 && (
        <Section>
          <div className="grid">
            <div className="col col20 s3">
              <FormField label=" " inline>
                <Button onClick={() => setPage(page - 1)} disabled={page === 1}>Anterior</Button>
                <Button onClick={() => setPage(page + 1)} disabled={page === meta.last_page}>Siguiente</Button>
              </FormField>
            </div>
          </div>
        </Section>
      )}
      <Section>
        <div className="grid">
          <div className="col col20 s3">
            <div className="grid grid--models">
              {accessories === undefined && <p>Buscando...</p>}
              {accessories && accessories.length === 0 && <p>No se encontraron accesorios para tu búsqueda.</p>}
              {accessories !== undefined && accessories.map(accessory => (
                <Accesory
                  accessory={accessory}
                  key={accessory.id}
                  addToCart={addToCart}
                  inCart={cart.filter(({ id }) => id === accessory.id)}
                />
              ))}
            </div>
          </div>
        </div>
      </Section>

      <Footer />
    </>
  );
}

export default Accessories;
