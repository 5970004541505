import { Component } from "react";
import "./Seller.scss";
import WhatsappLogo from "images/whatsapp.svg";
import ZoomLogo from "images/zoom.svg";

export default class Seller extends Component {
  render() {
    const { name, whatsapp, zoom, image, initials } = this.props;

    let className = "seller";

    var whatsappElement;
    var zoomElement;
    if (whatsapp) {
      whatsappElement = (
        <div className="seller__whatsapp">
          {" "}
          <a href={`https://wa.me/${whatsapp}`} target="_blank" rel="noopener noreferrer">
            <span>
              <img src={WhatsappLogo} alt="WhatsApp" />
            </span>{" "}
            Contactar por WhatsApp
          </a>{" "}
        </div>
      );
    }
    if (zoom) {
      zoomElement = (
        <div className="seller__zoom">
          {" "}
          <a href={zoom}>
            <span>
              <img src={ZoomLogo} alt="Zoom" />
            </span>{" "}
            Llamar por zoom{" "}
          </a>{" "}
        </div>
      );
    }

    var imageElement;
    if (image) {
      imageElement = (
        <div className="seller__avatar">
          {" "}
          <img src={image} alt={name} />{" "}
        </div>
      );
    } else {
      imageElement = (
        <div className="seller__avatar">
          {" "}
          <span>{initials}</span>{" "}
        </div>
      );
    }

    return (
      <div className={className}>
        <div className="seller__image">{imageElement}</div>
        <div className="seller__info">
          <div className="seller__name">{name}</div>
          <div className="seller__contact">
            {whatsappElement}
            {zoomElement}
          </div>
        </div>
      </div>
    );
  }
}
