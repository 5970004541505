import { Component } from "react";
import "./Breadcrumbs.scss";
import MainLink from "../../Atoms/MainLink/index.jsx";

export default class Button extends Component {
  render() {
    const { linkList, withCurrent } = this.props;

    let className =
      "breadcrumbs" + (withCurrent ? " breadcrumbs--withCurrent" : "");

    var listElement;
    var listDivs = linkList.map((item) => (
      <div className="breadcrumbs__item" key={item.id}>
        <MainLink url={item.link} small>
          {item.name}
        </MainLink>
      </div>
    ));

    if (linkList) {
      listElement = <div>{listDivs}</div>;
    }

    return (
      <div className={className}>
        <div className="grid">
          <div className="col10 s3">{listElement}</div>
        </div>
      </div>
    );
  }
}
