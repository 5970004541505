import { Component } from "react";
import "./Basic.scss";

export default class Basic extends Component {
  render() {
    const {
      children,
      description,
      title,
      subtitle,
      layout,
      image,
      link,
    } = this.props;

    let className =
      "basic" +
      (layout ? " basic--" + layout : "") +
      (link ? " basic--link banner" : "");

    var subtitleElement;
    if (subtitle) {
      subtitleElement = <h5>{subtitle}</h5>;
    }

    var titleElement;
    if (title) {
      titleElement = <h3>{title}</h3>;
    }

    var descriptionElement;
    if (description) {
      descriptionElement = <p>{description}</p>;
    }

    var imageElement;
    var imageClass;

    if (image) {
      switch (layout) {
        case "basic-12":
          imageClass = "image image-16-9";
          break;
        case "basic-6":
          imageClass = "image image-4-3";
          break;
        case "basic-6-square":
          imageClass = "image image-1-1";
          break;
        case "inline-9":
          imageClass = "image image-16-9";
          break;

        default:
          imageClass = "image";
      }
      imageElement = (
        <div className={imageClass}>
          <img src={image} alt={title} />
        </div>
      );
    }

    var layoutElement;
    switch (layout) {
      case "basic-12":
        layoutElement = (
          <div>
            {imageElement}
            {subtitleElement}
            {titleElement}
            {descriptionElement}
            {children}
          </div>
        );
        break;
      case "basic-6":
        layoutElement = (
          <div>
            {imageElement}
            {subtitleElement}
            {titleElement}
            {descriptionElement}
            {children}
          </div>
        );
        break;
      case "basic-6-square":
        layoutElement = (
          <div>
            {imageElement}
            {subtitleElement}
            {titleElement}
            {descriptionElement}
            {children}
          </div>
        );
        break;
      case "inline-9":
        layoutElement = (
          <div className="grid">
            <div className="col9 s3">{imageElement}</div>
            <div className="col9 s14">
              {subtitleElement}
              {titleElement}
              {descriptionElement}
              {children}
            </div>
          </div>
        );
        break;
      default:
        layoutElement = (
          <div>
            {imageElement}
            {subtitleElement}
            {titleElement}
            {descriptionElement}
            {children}
          </div>
        );
    }

    var linkElement;
    if (link) {
      linkElement = <a href={link}>{layoutElement}</a>;
    } else {
      linkElement = layoutElement;
    }

    return <div className={className}>{linkElement}</div>;
  }
}
