import axios from 'axios';
import Button from "Components/Atoms/Button/index.jsx";
import ExpirationBox from './ExpirationBox';
import FormRow from "Components/Atoms/Forms/FormRow/index.jsx";
import FormField from "Components/Atoms/Forms/FormField/index.jsx";
import TextField from "Components/Atoms/Forms/TextField/index.jsx";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSavingsPlan, useParameter } from "Components/Context/Parameters";

const BenefitBox = ({ onSuccess, showLink = false, couponCode = '', plan, planCoupon = null }) => {
  const [error, setError] = useState(false);
  const [code, setCode] = useState(couponCode);
  const [coupon, setCoupon] = useState(planCoupon);
  const [hasCoupon, setHasCoupon] = useState(planCoupon ? true : false);
  const contactMethods = useParameter('contact_methods');

  const whatsapp = contactMethods.reduce((whatsapp, method) => whatsapp || (method.department === 'plans' && method.whatsapp_number), null);

  useEffect(() => {
    if (code) {
      handleSubmit();
    }
  }, []); // eslint-disable-line

  function handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    setError(false);

    axios.post(`/api/vw/plans/validate`, { code })
      .then(response => {
        if (!response.data) {
          setError(true);
          return;
        }

        if (response.data?.plan !== plan.id) {
          setError(true);
          return;
        }

        setCoupon(response.data);
        if (onSuccess) {
          onSuccess(code);
        }
      })
      .catch(e => {
        setError(true);

        console.error(e);
      });
  }

  function handleChange(e) {
    setCode(e.currentTarget.value);

    setCoupon(null);
  }

  if (!hasCoupon) {
    return (
      <div className="grid">
        <div className="col col10 s7">
          <p>
            Si te comunicaste con uno de nuestros asesores de Autoahorro
            Volkswagen y te generó un cupón de beneficios, colocá el código
            aquí para validarlo.
          </p>
          {whatsapp && (
            <p>Si todavía no tenés tu cupón, escribinos por <a href={`https://wa.me/${whatsapp}`} target="_blank" rel="noopener noreferrer">WhatsApp</a></p>
          )}
          <p>
            <Button type="button" onClick={() => setHasCoupon(true)}>Tengo un cupón</Button>
          </p>
          <p>
            <small><a href="#tus-datos">Continuar sin cupón</a></small>
          </p>
        </div>
      </div>
    )
  }

  if (coupon) {
    return (
      <div className="grid">
        <div className="col col10 s7">
          {!showLink && (
            <>
              <ExpirationBox coupon={coupon} code={code} />
              <a href="#tus-datos"><Button>Continuar con este cupón</Button></a>
            </>
          )}
          {showLink && (
            <p>
              Podés subscribirte a través del siguiente botón: <br />
              <Link to={`/autoahorro/subscripcion/${plan.family}/${plan.id}/${code}`}>
                <Button text="Subscribirme a Autoahorro" />
              </Link>
            </p>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="grid">
      <div className="col col10 s7">
        <p>
          Si te comunicaste con uno de nuestros asesores de Autoahorro
          Volkswagen y te generó un cupón de beneficios, colocá el código
          aquí para validarlo.
        </p>
        <form onSubmit={handleSubmit}>
          <FormRow>
            <FormField inline>
              <TextField
                placeholder="0000-0000"
                name="plancode"
                error={error}
                errorMessage={error ? 'El código no es válido o ha vencido' : ''}
                onChange={handleChange}
                value={code}
                required
              />
            </FormField>
            <FormField inline>
              <Button type="submit">Verificar código</Button>
            </FormField>
          </FormRow>
        </form>
      </div>
    </div>
  );
}

export default BenefitBox;
